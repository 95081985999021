* {
  box-sizing: border-box;
}

.display-none {
  display: none;
}

h1 {
  margin: 0;
  margin-bottom: 0.3em;
}

table {
  width: 100%;
}

table thead th,
table thead td {
  text-align: center;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.filter-control-box {
  margin: 0.5em;
  display: flex;
  justify-content: center;
}

button {
  outline: none;
  border: 0;
  cursor: pointer;
}

.filter-area {
  margin-top: 0.3em;
}

article {
  margin-bottom: 1em;
}

.navbar {
  font-size: 2rem;
  padding: 1em;
  background: blanchedalmond;
}

.navbar-logo {
  color: green;
  margin-right: 0.5em;
}

.navbar-count {
  display: inline-block;
  text-align: center;
  font-size: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-left: 0.2em;
  background-color: green;
  color: white;
  border-radius: 50%;
}

.add-input {
  font-size: 2rem;
}

.add-input {
  margin-right: 0.2em;
}

.add-button {
  height: 100%;
  font-size: 1.5rem;
  padding: 0.3em 0.5em;
  background-color: green;
  color: white;
}

.habit {
  font-size: 2.5rem;
  padding: 0.5em;
  list-style: none;
}

.habit-count {
  display: inline-block;
  text-align: center;
  font-size: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-left: 0.2em;
  margin-right: 0.5em;
  background-color: darkseagreen;
  color: white;
  border-radius: 50%;
}

.habit-button {
  font-size: 1.2rem;
  margin: 0 0.1em;
  background-color: transparent;
  border-radius: 5%;
}

.regist {
  background-color: #0b88fd;
  margin-right: 0.5em;
}

.save {
  background-color: #99ceff;
  margin-right: 0.5em;
}

.reset {
  background-color: #ffa791;
}

.habit-button:hover {
  opacity: 0.8;
}

.habit-increase,
.habit-decrease {
  color: goldenrod;
}

.habit-delete {
  color: red;
}

.habits-reset {
  font-size: 1.5rem;
  padding: 0.2em 1em;
  background-color: green;
  color: white;
}

table td {
  /* text-align: right; */
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg th {
  border-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg tbody tr:nth-child(even) {
  background-color: #dae8fc;
  border-color: #ffffff;
  text-align: left;
  vertical-align: top;
}
.tg thead tr {
  background-color: #c0c0c0;
  border-color: #ffffff;
  text-align: left;
  vertical-align: top;
}
.tg tbody tr:nth-child(odd) {
  background-color: beige;
  border-color: #ffffff;
  text-align: left;
  vertical-align: top;
}

select {
  width: 100%;
}

p {
  font-size: 0.8em;
}

ul {
  padding-left: 2em;
}

select {
  height: 2em;
}

.display-flex {
  display: flex;
}

.width25 {
  width: 25%;
}

.searchBox label {
  margin-bottom: 6px;
  display: block;
}

.modal {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modalContent {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalLayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.inVisible {
  display: none;
}